import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { Link } from "gatsby";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Masterclass Àmbit A Mossos d'Esquadra (2023/24)</Title>
                <Text>
                    Opos Academia informa que tornem a portar la MASTERCLASS D'ÀMBIT A en directe.
                    <br />
                    <br />
                    Una formación totalment renovada i actualitzada en base a la propera
                    convocatoria
                    <br />
                    <br />
                    El curs s'impartirà:
                    <br />
                    <br />
                    Diumenge 3 de desembre, de 17.00 a 21.00h
                    <br />
                    <br />
                    La clase es farà en directe mitjançant Zoom.
                    <br />
                    <br />
                    La sessió quedarà grabada al campus fins al dia 3 de gener per poder veure-ho en
                    cas de no poder asistir a la sessió o simlement per veure-ho de nou.
                    <br />
                    <br />
                    La masterclass consta d'un repàs intens dels conceptes més importants i
                    habituals de l'examen d'aquest àmbit.
                    <br />
                    <br />
                    <strong>Únic pagament de 39,99 €</strong>
                    <br />
                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/lzdNHaNuXY0"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        />
                    </div>
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
